
<template>
  <div class="con manage-scroll">
    <div class="sta-content">
      <div class="sta-one" v-loading="loading1" v-if="sensorName.indexOf('A1') !== -1">
        <chart-title :sensor-detai-list="sensorDetaiList" :sensor-show="true" :range-show="true" :title-text="chartData1.title"  :sensor-type="sensorName" @changeForm="getData"></chart-title>
        <div class="ch-text">最小值：{{chartData1.min ? chartData1.min : '--'}} 最大值：{{chartData1.max ? chartData1.max : '--'}} 最小值出现时间：{{chartData1.minTime ? chartData1.minTime : '--'}} 最大值出现时间：{{chartData1.maxTime ? chartData1.maxTime : '--'}}</div>
        <chart-two :value="chartData1"></chart-two>
      </div>
      <div class="sta-one" v-loading="loading2" v-if="sensorName.indexOf('B1') !== -1">
        <chart-title :sensor-detai-list="sensorDetaiList1" :title-text="chartData2.title" :sensor-type="sensorName" o :sensor-show="true" :range-show="true" @changeForm="getData1"></chart-title>
        <div class="ch-text">最小值：{{chartData2.min ? chartData2.min : '--'}} 最大值：{{chartData2.max ? chartData2.max : '--'}} 最小值出现时间：{{chartData2.minTime ? chartData2.minTime : '--'}} 最大值出现时间：{{chartData2.maxTime ? chartData2.maxTime : ''}}</div>
        <chart-two :value="chartData2"></chart-two>
      </div>
      <div class="sta-one" style="height: 500px"  v-loading="loading3" v-if="sensorName.indexOf('A2') !== -1">
        <chart-title :timeArr="timeList" :sensor-detai-list="sensorDetaiList" title-text="分布图" :sensor-show="true" :range-show="true" @changeForm="getData2"></chart-title>
        <chart-three :value="chartData3"></chart-three>
      </div>
      <div class="sta-one" style="height: 500px"  v-loading="loading4" v-if="sensorName.indexOf('B2') !== -1">
        <chart-title :timeArr="timeList" :sensor-detai-list="sensorDetaiList1" title-text="分布图" :sensor-show="true" :range-show="true" @changeForm="getData3"></chart-title>
        <chart-three :value="chartData4"></chart-three>
      </div>
    </div>
  </div>
</template>

<script>
  import chartTwo from "../charts/chart02.vue";
  import chartThree from "../charts/chart03.vue";
  import chartTitle from "../../common/components/chartTitle"
  export default {
    components: {
      chartTwo,chartThree,chartTitle
    },
    data() {
      return {
        loading1:false,
        loading2:false,
        loading3:false,
        loading4:false,
        chartData: {
          title:'温湿度统计图',
          xAxis:['东侧上游','东侧上游','东侧上游','东侧上游','东侧上游','东侧上游','东侧上游','东侧上游','东侧上游'],
          data1:[10,9,10,8,7,10,9,7,8],
          data2:[7,6,6,7,5,8,7,5,6]
        },
        chartData1: {
          title:'温度散点图',
          yAxis:{
            name:'TEM-01(℃)',
            min:0,
            max:0
          },
          xAxis:[],
          data1:{
            name:'实测值',
            data:[]
          },
          markLine:[],
          markLineList:[],
          colorList: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
          min:0,
          max:0,
          maxTime:'',
          minTime:''
        },
        chartData2: {
          title:'湿度散点图',
          yAxis:{
            name:'TEM-01(℃)',
            min:0,
            max:0
          },
          xAxis:[],
          data1:{
            name:'实测值',
            data:[]
          },
          markLine:[],
          markLineList:[],
          colorList: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
          min:0,
          max:0,
          maxTime:'',
          minTime:''
        },
        chartData3: {
          xAxis: {
            name:'温度',
            data:[]
          },
          yAxis: {
            name:'频数'
          },
          data:[]
        },
        chartData4: {
          xAxis: {
            name:'湿度',
            data:[]
          },
          yAxis: {
            name:'频数'
          },
          data:[]
        },
        sensorDetaiList:[],
        sensorDetaiList1:[],
        sensorName:'',
        timeList:[]
      };
    },
    created() {
      let date = new Date();
      this.timeList[0] = this.$moment(date).subtract(4, "days").format("YYYY-MM-DD");
      this.timeList[1] = this.$moment(date).format("YYYY-MM-DD");
    },
    methods:{
      changeSensorDetail(data){
        if (data.length > 0){
          this.sensorDetaiList = []
          this.sensorDetaiList1 = []
          this.sensorName = data[0].chart
          if (data){
            for (let item of data){
              if (item.type === 'T'){
                this.sensorDetaiList.push(item)
              } else {
                this.sensorDetaiList1.push(item)
              }
            }
          }
        }
      },
      getData(data){
        let params = {
          type:0,
          sensorId:data.sensorId,
        }
        if (data.time.length > 0){
          if (data.time[0].length < 12){
            data.time = [data.time[0] + ' 00:00:00',data.time[1] + ' 23:59:59']
          }
          params.startTime = data.time[0]
          params.endTime = data.time[1]
        }
        if (data.sensorId && data.time.length > 0){
          this.loading1 = true
          this.$http.post("/statistics/environment/humiture/scatter",params).then(res=>{
            if(res.success){
              this.chartData1.xAxis = res.data.xaxis
              this.chartData1.data1.data = res.data.scatterData.yaxis
              this.chartData1.markLine = JSON.parse(JSON.stringify(res.data.markLineList))
              this.chartData1.yAxis.min = Math.floor(res.data.ymin);
              this.chartData1.yAxis.max = Math.ceil(res.data.ymax);
              this.chartData1.markLineList = JSON.parse(JSON.stringify(res.data.markLineList))
              this.chartData1.min = res.data.min.toFixed(1);
              this.chartData1.max = res.data.max.toFixed(1);
              this.chartData1.maxTime = res.data.maxTime
              this.chartData1.minTime = res.data.minTime
            } else {
              this.loading1 = false
            }
          })
        }
      },
      getData1(data){
        let params = {
          type:1,
          sensorId:data.sensorId,
        }
        if (data.time.length > 0){
          if (data.time[0].length < 12){
            data.time = [data.time[0] + ' 00:00:00',data.time[1] + ' 23:59:59']
          }
          params.startTime = data.time[0]
          params.endTime = data.time[1]
        }
        if (data.sensorId && data.time.length > 0){
          this.loading2 = true
          this.$http.post("/statistics/environment/humiture/scatter",params).then(res=>{
            if(res.success){
              this.chartData2.xAxis = res.data.xaxis
              this.chartData2.data1.data = res.data.scatterData.yaxis
              this.chartData2.markLine = JSON.parse(JSON.stringify(res.data.markLineList))
              this.chartData2.yAxis.min = Math.floor(res.data.ymin);
              this.chartData2.yAxis.max = Math.ceil(res.data.ymax);
              this.chartData2.markLineList = JSON.parse(JSON.stringify(res.data.markLineList))
              this.chartData2.min = res.data.min.toFixed(1);
              this.chartData2.max = res.data.max.toFixed(1);
              this.chartData2.maxTime = res.data.maxTime
              this.chartData2.minTime = res.data.minTime
            } else {
              this.loading2 = false
            }
          })
        }
      },
      getData2(data){
        let params = {
          type:0,
          sensorId:data.sensorId
        }
        if (data.time.length > 0){
          if (data.time[0].length < 12){
            data.time = [data.time[0] + ' 00:00:00',data.time[1] + ' 23:59:59']
          }
          params.startTime = data.time[0]
          params.endTime = data.time[1]
        }
        if (data.sensorId && data.time.length > 0){
          this.loading3 = true
          this.$http.post("/statistics/environment/humiture/histogram",params).then(res=>{
            if(res.success){
              this.chartData3.xAxis.data = res.data.xaxis
              this.chartData3.data = res.data.yaxis
            } else {
              this.loading3 = false
            }
          })
        }
      },
      getData3(data){
        let params = {
          type:1,
          sensorId:data.sensorId,
        }
        if (data.time.length > 0){
          if (data.time[0].length < 12){
            data.time = [data.time[0] + ' 00:00:00',data.time[1] + ' 23:59:59']
          }
          params.startTime = data.time[0]
          params.endTime = data.time[1]
        }
        if (data.sensorId && data.time.length > 0){
          this.loading4 = true
          this.$http.post("/statistics/environment/humiture/histogram",params).then(res=>{
            if(res.success){
              this.chartData4.xAxis.data = res.data.xaxis
              this.chartData4.data = res.data.yaxis
            } else {
              this.loading4 = false
            }
          })
        }
      },
      changeCommonLoad2(){
        if (this.loading1 === true){
          this.loading1 = false
        }
        if (this.loading2 === true){
          this.loading2 = false
        }
      },
      changeCommonLoad3(){
        if (this.loading3 === true){
          this.loading3 = false
        }
        if (this.loading4 === true){
          this.loading4 = false
        }
      }
    }
  };
</script>

<style scoped>
  .manage-scroll{
    height: calc(100% - 400px);
  }
  .sta-title{
    height: 60px;
    padding: 0 20px;
    background: #fff;
    margin-bottom: 10px;
  }
  .title-text{
    font-size: 16px;
    color: #1A67D0;
    font-weight: 900;
  }
  .sta-one{
    width: 100%;
    height: 400px;
    background: #fff;
    margin-bottom: 10px;
    padding: 20px;
    position: relative;
  }
  .sta-two{
    width: 100%;
    height: 430px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .sta-con{
    width: 49.7%;
    height: 100%;
    padding: 20px;
    background: #fff;
  }
  /deep/ .el-form-item{
    margin-bottom: 0;
  }
  /deep/ .el-date-editor .el-range-separator{
    padding: 0;
  }
  .threshold{
    position: absolute;
    right: 0;
    top: 94px;
    width: 144px;
  }
  .th-item{
    display: flex;
    align-items: center;
    height: 25px;
    line-height: 25px;
  }
  .th-item:hover{
    cursor: pointer;
  }
  .th-icon{
    width: 20px;
    height: 2px;
    margin-right: 10px;
  }
  .ch-text{
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
  }
</style>
