<template>
  <div ref="dom" class="charts chart-line"></div>
</template>

<script>
  import * as echarts from "echarts";
  import { on, off } from "@/libs/tools";
  export default {
    name: "ChartBar",
    props: {
      value: Object
    },
    data() {
      return {
        dom: null,
      };
    },
    watch: {
      value: {
        handler(val) {
          this.loadEcharts(val);
        },
        deep: true,
        immediate: true,
      },
    },
    mounted() {},
    methods: {
      resize() {
        this.dom.resize();
      },
      loadEcharts(val) {
        this.$nextTick(() => {
          let markLine = []
          for(let i =0;i < val.markLineList.length;i++){
            let color = ''
            if (val.markLineList[i].name.substr(0,4) === '一级阈值'){
              color = '#61A0A8'
            } else if (val.markLineList[i].name.substr(0,4) === '二级阈值'){
              color = '#C23531'
            } else if (val.markLineList[i].name.substr(0,4) === '三级阈值'){
              color = '#CA8622'
            }
            markLine.push({
              name: val.markLineList[i].name,
              yAxis: val.markLineList[i].data,
              lineStyle:{
                type:'solid',
                color:color,
                cap:'butt'
              }
            });
          }
          let option = {
            tooltip: {
              show:false
            },
            grid:{
              top:30,
              left:50,
              bottom:30,
              right:150
            },
            legend: {
              show: true,
              right:0,
              top:'1%',
              orient:'vertical',
              data:[val.data1.name],
              selected:{}
            },
            xAxis: {
              type: 'category',
              data: val.xAxis,
              splitLine:{
                show:true
              },
              axisLine:{
                onZero:false
              },
              boundaryGap:false
            },
            yAxis: {
              name:val.yAxis.name,
              type: 'value',
              nameLocation:'center',
              splitLine: {
                show:true
              },
              max:val.yAxis.max,
              min:val.yAxis.min,
              nameGap:35,
              // interval:5
            },
            series: [
              {
                name: val.data1.name,
                type: "scatter",
                data:val.data1.data,
                symbolSize:3,
              },
            ],
          };
          markLine.forEach(item => {
            option.legend.data.push(item.name)
            option.series.push({
              type:'line',
              name:item.name,
              symbol:'none',
              itemStyle: {
                color:item.lineStyle.color,
              },
              lineStyle:{
                color:item.lineStyle.color,
              },
              markLine: {
                symbol:'none',
                data: [item],
              }
            })
            option.legend.selected[item.name] = false
          });
          if (this.dom != null && this.dom != "" && this.dom != undefined) {
            this.dom.dispose();
          }
          this.dom = echarts.init(this.$refs.dom);
          this.dom.setOption(option);
          on(window, "resize", this.resize);
          this.$parent.changeCommonLoad2()
        });
      },
    },

    beforeDestroy() {
      off(window, "resize", this.resize);
    },
  };
</script>
<style scoped>
  .charts{
    width: 100%;
    height: calc(100% - 60px);
  }
</style>
