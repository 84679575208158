<template>
  <div class="single-structure-change">
    <module-one @monitorChange="monitorChange" text="ENV"></module-one>
    <div class="nav-list">
      <div :class="navActive === index ? 'nav-con' : 'nav-con nav-con-active'" v-for="(item,index) in navList" :key="index" @click="navClick(item,index)">
        <i :class="item.icon"></i>
        <span>{{item.name}}</span>
      </div>
    </div>
    <div class="flex-line" v-if="navActive === 0">
      <module-three :dataType="dataType" @typeChange="typeChange" class="flex-left" ref="currentLine"></module-three>
      <module-two class="flex-right" ref="currentTable" :type="dataType" :sensorData="lineData" :page-data="pageData" :page-loading="pageLoading"></module-two>
    </div>
    <module-four :dataType="dataType" ref="featureLine" v-if="navActive === 1"></module-four>
    <module-five ref="warnTable" v-if="navActive === 2"></module-five>
    <module-six ref="statistics" v-if="navActive === 3"></module-six>
  </div>
</template>

<script>
  import Stomp from 'stompjs'
  import moduleOne from "../common/components/moduleOne";
  import moduleTwo from "../common/components/moduleTwo.vue";
  import moduleThree from "../common/components/moduleThree.vue";
  import moduleFour from "../common/components/moduleFour.vue";
  import moduleFive from "../common/components/moduleFive.vue";
  import moduleSix from "./components/moduleSix.vue";
  export default {
    components: {
      moduleOne,
      moduleTwo,
      moduleThree,
      moduleFour,
      moduleFive,
      moduleSix
    },
    data() {
      return {
        client: null,
        topic: null,
        sensor: null,
        list:[],
        timer:null,
        pageData:[],
        pageLoading:false,
        navList:[
          {value:0,name:'时程数据',icon:'el-icon-share'},
          {value:1,name:'特征数据',icon:'el-icon-data-line'},
          {value:2,name:'报警数据',icon:'el-icon-bell'},
          {value:3,name:'数据统计',icon:'el-icon-data-analysis'}
        ],
        navActive:0,
        sensorData:null,
        scoketTimer:null,
        lineData:[],
        dataType: 1
      };
    },
    created() {
      let that = this
      that.timer = setInterval(function () {
        that.$refs.currentLine.getMqData(that.list);
        that.list = []
      },1000)
    },
    beforeDestroy(){
      this.disconnect();
      clearInterval(this.timer);
      this.timer = null;
      if (this.scoketTimer){
        clearInterval(this.scoketTimer);
        this.scoketTimer = null;
      }
    },
    methods: {
      // 相对、绝对切换
      typeChange(val) {
        this.dataType = val;
      },
      //桥模型上圆点点击事件
      clickPoint(point){
        if (this.navActive === 0){
          this.$refs.currentLine.changeByPoint(point)
        } else if(this.navActive === 1){
          this.$refs.featureLine.changeByPoint(point)
        }
      },
      monitorChange(data) {
        this.navActive = 0;
        this.sensorData = data
        this.disconnect();
        this.currentTableData(data); //数据列表
        this.currentsensorData(data)
      },
      navClick(data,i){
        this.navActive = i
        this.disconnect();
        clearInterval(this.timer);
        this.timer = null;
        let that = this
        if (i === 0){
          that.timer = setInterval(function () {
            that.$refs.currentLine.getMqData(that.list);
            that.list = []
          },1000)
          this.currentTableData(this.sensorData); //数据列表
        }
        this.currentsensorData(this.sensorData)
      },
      currentTableData(data) {
        this.pageLoading = true
        this.$http.get("/common/currentTableData",{
          params:{
            monitorDictId: data.id
          }
        }).then(res=>{
          if(res.success){
            this.pageData = res.data;
          }
          this.pageLoading = false
        })
      },
      currentsensorData(data) {
        this.pageLoading = true
        this.$http.get("/sensorDetail/getListByMonitorDictId",{
          params:{
            monitorDictId: data.id
          }
        }).then(res=>{
          if(res.success){
            if(res.data && res.data.length > 0){
              if (!res.data[0].chart){
                this.navList = [
                  {value:0,name:'时程数据',icon:'el-icon-share'},
                  {value:1,name:'特征数据',icon:'el-icon-data-line'},
                  {value:2,name:'报警数据',icon:'el-icon-bell'},
                ]
              } else {
                this.navList = [
                  {value:0,name:'时程数据',icon:'el-icon-share'},
                  {value:1,name:'特征数据',icon:'el-icon-data-line'},
                  {value:2,name:'报警数据',icon:'el-icon-bell'},
                  {value:3,name:'数据统计',icon:'el-icon-data-analysis'}
                ]
              }
            }
            if (this.navActive === 0){
              var p1 = this.currentLineData(res.data,data.name); //时程数据
              this.lineData = res.data
              Promise.all([p1]).then(values => {
                this.startListen();
              }, () => {});
            } else if (this.navActive === 1){
              this.featureLineData(res.data); //最值、均值数据
            } else if (this.navActive === 2){
              this.warnTableData(res.data);
            } else if (this.navActive === 3){
              this.statisticsData(res.data,data.name); //统计
            }
          }
          this.pageLoading = false
        })
      },
      //时程数据
      currentLineData(data,name){
        return this.$refs.currentLine.currentLineData(data,name)
      },
      featureLineData(data){
        this.$refs.featureLine.featureLineData(data);
      },
      warnTableData(data){
        this.$refs.warnTable.getSensorDetail(data);
      },
      statisticsData(data,name){
        this.$refs.statistics.changeSensorDetail(data,name);
      },
      //开启监听mq
      startListen(){
        console.log('开始连接');
        this.topic = this.$refs.currentLine.topic
        var timestamp = new Date().getTime();
        var clientid = timestamp + Math.floor(Math.random() * 10000);
        var headers = {
          login: this.$MQ_USER,
          passcode: this.$MQ_PWD,
          "client-id": clientid
        };
        if (!this.client || !this.client.connected) {
          this.client = Stomp.client(this.$MQURL);
          this.client.debug = null;
        }
        this.client.connect(
          headers,
          this.onConnected,
          this.onFailed
        );
      },
      onConnected: function(frame) {
        console.log('连接成功');
        clearInterval(this.scoketTimer);
        this.scoketTimer = null;
        var topic = "/topic/"+this.topic;
        // 订阅频道
        this.client.subscribe(topic, this.responseCallback, this.onFailed);
        this.client.debug = null;
      },
      onFailed: function(frame) {
        console.log("Failed: " + frame);
        let self = this
        this.scoketTimer = setInterval(function () {
          self.startListen()
        },5000)
      },
      responseCallback: function(frame) {
        var that = this;
        let body = JSON.parse(frame.body);
        body.forEach(item => {
          that.list.push(item)
        })
        this.$refs.currentTable.getMqData(body[body.length - 1]);
      },
      disconnect: function() {
        if(this.client){
          let that = this
          that.client.disconnect(function(e) {
            that.client = null
          });
        }
      },
    },
  };
</script>
<style scoped>
  .single-structure-change{
    height: 100%;
  }
  .nav-list{
    width: 100%;
    height: 40px;
    line-height: 40px;
    display: flex;
  }
  .nav-con{
    width: 140px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border-left: 1px solid #1A67D0;
    border-right: 1px solid #1A67D0;
    border-top: 1px solid #1A67D0;
    color: #1A67D0;
    font-weight: bold;
  }
  .nav-con:hover{
    cursor: pointer;
  }
  .nav-con i{
    color: #1A67D0;
    margin-right: 5px;
    font-weight: bold;
    font-size: 20px;
  }
  .nav-con-active{
    color: #fff;
    background: #1A67D0;
    border-bottom: 1px solid #1A67D0;
  }
  .nav-con-active i{
    color: #fff;
  }
  .flex-line{
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: #fff;
    margin-bottom: 20px;
  }
  .flex-left{
    width: 73%;
  }
  .flex-right{
    width: 26%;
  }
</style>
